
import { Component, Prop, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/order, import/extensions
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getView, randomAssetNameForDemo } from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import FuzzySearch from 'fuzzy-search';

import accountModule from '@/store/modules/accountModule';
import gptModule from '@/store/modules/gptModule';
import Multiselect from 'vue-multiselect';
import CustomHeader from '@/views/agGrid/aiGroupsHeader.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    agColumnHeader1: CustomHeader,
    FormDetailPopup: () => getView('FormDetailPopup'),
    BarChart: () => import('@/lib/charts/barChart'),
    AgGridVue,
    Multiselect,
  },
})
export default class GroupDataView extends Vue {
  @Prop({ type: Array, required: true, default: [] }) group?: any;


  showSendVendor = false;

  showSendContractor = false;

  groupOptions = ['Realtime', 'Off-Target', 'Setpoint', 'Facility ESD','SHUT/BOOT','MAIN SD/SHUT/D'];

  selectedTrigger = ['Realtime'];

  removeSelectedForm(form) {
    this.selectedForms = this.selectedForms.filter((f) => f.id !== form.id);
  }
  barChartData: any = {
    labels: ['Well A', 'Well B', 'Well C', 'Well D', 'Well F', 'Well G'], // Example wells
    datasets: [
      {
        label: 'False Positive Rate',
        labelColor: '#FFFFFF', // Set label color to white
        backgroundColor: [
          '#f87979',
          '#79f879',
          '#7979f8',
          '#f8c779',
          '#7979f8',
          '#f8c779',
        ], // Unique colors for each well
        data: [10, 20, 15, 5, 10, 20, 15, 5], // Replace with actual false positive rates for each well
      },
    ],
  };

  closeTasq = false;

  showNewSections(option){
    if(option === 'Send to Contractor'){
      this.showSendContractor = !this.showSendContractor;
    }else if(option === 'Send to Vendor'){
      this.showSendVendor = !this.showSendVendor;
      // this.showSendContractor = false;
    }
  }

  barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'False Positive Rate (%)',
          color: '#FFFFFF', // Set y-axis title color to white
        },
        ticks: {
          color: '#FFFFFF', // Set y-axis ticks color to white
        },
      },
      x: {
        title: {
          display: true,
          text: 'Wells',
          color: '#FFFFFF', // Set x-axis title color to white
        },
        ticks: {
          color: '#FFFFFF', // Set x-axis ticks color to white
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF', // Set legend text color to white
        },
      },
      title: {
        display: true,
        text: 'False Positive Rate by Well',
        color: '#FFFFFF', // Set chart title color to white
      },
    },
  };

  context: any = null;

  searchQuery = '';

  columnDefs: any = [];

  selectedForms: any = [];

  formCategories: any = [];

  width: any = window.innerWidth;
  formArtificialLiftTypes: any = [];

  deleteFormPopup = false;

  formID = '';

  showFormFilter = false;

  showFormDetail = false;

  formFilter: any = {
    categoriesSelected: [],
    titlesSelected: [],
    lastEditorSelected: [],
    lastEditStartDate: '',
    lastEditEndDate: '',
  };

  defaultColDef = {
    initialWidth: 250,
    sortable: true,
    filter: true,
    editable: false,
    autoHeight: true,
    cellStyle: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible',
      'white-space': 'normal',
    },
    resizable: true,
  };

  rowData: any = [];

  cellClick() {
    // console.log(e);
  }

  get availableForms() {
    return metaDataModule.formBuilderData;
  }

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  dataLoading = false;

  async created() {
    this.fetchActions();
    this.groupOptions = [...this.groupOptions, this.group[0].typeOfData];
    this.selectedTrigger = [this.group[0].typeOfData];

    await metaDataModule.getActionsBuilder({ useExisting: true });
  }

  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  headers = [
    'WELL Name',
    'DEFERMENT',
    'Route',
    'CYCLE TIME',
    'LAST ACTIVITY TIME',
    '',
  ];
  columns = [
    { key: 'wellName', editable: false },
    { key: 'defermentValue', editable: false },
    { key: 'route', editable: true },
    { key: 'cycleTime', editable: true },
    { key: 'lastThirtyDaysCount', editable: false },
    { key: 'copyShare', editable: false },
  ];

  randomAssetNameForDemo = randomAssetNameForDemo;

  async fetchActions() {
    console.log(this.group);
    this.dataLoading = true;
    const group = this.group.map((t) => {
      const latestLastAction = gptModule.getLatestLastAction(t.wellName);
      return {
        wellName: randomAssetNameForDemo('Well', t.wellName),
        defermentValue: t.defermentValue
          ? t.defermentValue + ' ' + t.defermentUnit
          : '-',
        route: randomAssetNameForDemo('Route', t.route),
        cycleTime: t.dateAgoText || '-',
        lastThirtyDaysCount: latestLastAction
          ? this.formatDate(latestLastAction.point_time)
          : '-',
      };
    });
    console.log(group);
    this.rowData = group;
    this.dataLoading = false;
  }
}
